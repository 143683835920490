import React, { useState, useEffect } from 'react';
import { Box, Image, Text, VStack, Link as ChakraLink } from '@chakra-ui/react';
import { useLanguage } from '../LanguageContext';
import { useNavigate,useLocation } from 'react-router-dom';

function IdeaFlowBankHome() {
  const { language, setLanguage } = useLanguage();
  const [texts, setTexts] = useState({});
  const [services, setServices] = useState({});
  const navigate = useNavigate();
  const location = useLocation();  // ルートのロケーションを取得

  useEffect(() => {
    fetch('/IdeaFlowBank/IdeaFlowBankHome.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      }).then(data => {
        setTexts(data);
        setServices(data.services);
      })
      .catch(error => {
        console.error('There was a problem with fetch operation:', error);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);


  return (
    <Box p={5}  marginTop="250px">
      <VStack align="stretch" alignItems="center" marginX={["20px","150px"]}>
        <Text
            fontSize={{ base: "30px", md: "40px", lg: "60px" }}
            fontFamily="Montserrat"
            fontStyle="italic"
            fontWeight="900"
            letterSpacing={{ base: "-1.0px", md: "-1.5px" }}
            color="#5ABCB9"
            marginBottom="20px"
            sx={{
                WebkitTextStroke: "1px white",
                position: "relative", 
                mt: { base: "-130px", md: "-130px", lg: "-130px" },
                opacity: 0.8, 
            }}
                >
                Idea flow Bank
         </Text>
        <Text fontSize={["14px","18px"]}>{texts.introduction?.[language]}</Text>

        <Text fontSize={["18px","24px"]} color="#5ABCB9" fontWeight="bold"  marginTop={["36px","48px"]}>
             {language === 'JP' ? 'Idea Flow Bankの構成' : 'Composition of Idea Flow Bank'}
        </Text>
        <Image marginTop={["14px","18px"]} src={`/IdeaFlowBank/IdeaFlowBankHome.001.png`} alt="Image 1" />
        <Text  marginTop={["14px","18px"]} fontSize={["14px","18px"]}>{texts.composition1?.[language]}</Text>
        <Text   marginTop={["8px","10px"]} fontSize={["14px","18px"]}>{texts.composition2?.[language]}</Text>
        
        <Text fontSize={["18px","24px"]}  color="#5ABCB9" fontWeight="bold" marginTop={["36px","48px"]}>
             {language === 'JP' ? 'Idea Flow Bankのサポートシステム' : 'Support System for Idea Flow Bank'}
        </Text>
        <Image 
            src={language === 'JP' 
                ? '/IdeaFlowBank/SupportList-JP.png' 
                : '/IdeaFlowBank/SupportList-EN.png'}
            alt="Image 2" 
            marginTop={["14px","18px"]}
            border="2px solid #ccc"
            borderRadius="md"
        />
        <Text fontSize={["14px","18px"]} marginTop={["14px","18px"]}>{texts.support1?.[language]}</Text>
        <Text fontSize={["14px","18px"]} marginTop={["8px","10px"]}>{texts.support2?.[language]}</Text>
        <Text fontSize={["14px","18px"]} marginTop={["8px","10px"]}>{texts.support3?.[language]}</Text>
        <Box padding={5}>
         {Object.entries(services).map(([key, { title, description }]) => (
            <Box key={key}>
            <Text fontSize={["14px","18px"]} fontWeight="bold">
                {title[language]}
            </Text>
            <Text fontSize={["14px","18px"]}>
                {description[language]}
            </Text>
        　</Box>
        ))}
        </Box>

        <Text fontSize={["18px","24px"]}  color="#5ABCB9" fontWeight="bold" marginTop={["0px","24px"]}>
             {language === 'JP' ? 'DAO 体験シミュレーションゲーム' : 'Simuation Game for DAO experience'}
        </Text>
        <Image 
            src={`/IdeaFlowBank/Simulation-Game.png`} 
            alt="Image 3"
            />
        <Text 
            fontSize={["14px","18px"]}
            marginTop={["14px","18px"]}
            marginBottom={["36px","24px"]}
            >{texts.Dao_simulation_game?.[language]}
        </Text>

        <Text 
            fontSize={["10px","14px"]}
        >
            {texts.Note1?.[language]}
        </Text>
        <Box textAlign="left" width="100%">
            <Text fontSize={["10px", "12px"]}>
                <ChakraLink 
                    onClick={() => navigate('/legal-status')} 
                    style={{ cursor: 'pointer', color: 'blue.500' }}
                    textDecoration="underline"  // 下線を引くプロパティを直接設定
                    cursor="pointer"
                    color="blue.500"
                >
                     {language === 'JP' ? '※ DAOとしてのIdea Flow Bankの法的位置付け' : 'Legal Status of the Idea Flow Bank as a DAO'}
                </ChakraLink>
            </Text>
        </Box>
        <Text 
            fontSize={["10px","14px"]}
            marginBottom={["10vh","20vh"]}
        >
            {texts.Note2?.[language]}
        </Text>
      </VStack>
    </Box>
  );
}

export default IdeaFlowBankHome;
