import { Flex, Box, Text, useBreakpointValue, } from '@chakra-ui/react';
import React,{useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useLanguage } from './LanguageContext'; 

const 
  Top = () => {
  useEffect(() => {
    AOS.init(); // AOSを初期化
  }, []);

  const buttonTextSize = useBreakpointValue({ base: '14pt', md: '20pt' });
  const { language } = useLanguage();
  return (
    <Flex
      height={["40vh","60vh","80vh","100vh"]}
      direction="column"
      justify="start"
      backgroundImage="top_contact2.jpg"
      backgroundPosition={{ base: "center", md: "center" }} 
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      position="relative"
      marginTop="85px"
    >
      <Box
        position="relative"
        marginleft={["0px","0px","0px","80px"]}
        marginTop={["30px","30px","30px","80px"]}
        width={["300px","400px","500px","725px"]}
        height={["200px","300px","300px","500px"]}
        bg={["#5ABCB940","#5ABCB940","#5ABCB940","#5ABCB940"]} 
        padding="4"
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {language === 'JP' ? (
        <Box>
          <Text
            as="h1"
            fontSize={["20px","30px","36px", "56px"]}
            fontWeight="bold"
            fontFamily="Hiragino Kaku Gothic Std, sans-serif"
            color="white"
            mt={["10px","63px","63px","63px"]}
            textAlign="left"
            ml="48px"
          >
            誰もが<br/>
            軽やかに<br/>
            ワクワクしながら<br/>
            働ける社会へ
          </Text>
          </Box>
        ) : (
          <Text
            as="h1"
            fontSize={["20px","30px","30px", "56px"]}
            fontWeight="bold"
            fontFamily="Helvetica, sans-serif"
            color="white"
            mt={["10px", "63px"]}
            textAlign="left"
            ml="48px"
          >
            Towards a society where people can work with excitement.
          </Text>
        )}
      </Box>

      <Box 
        textAlign="left" 
        transform="rotate(180deg)" 
        position="absolute" 
        top={["3","5"]} 
        right={["2","0","0","0"]}
      >
        <Text
          fontSize={["28px","50px","58px","75px"]}
          data-aos="fade-up-right"
          fontStyle="italic"
          fontWeight="bold"
          fontFamily="Helvetica Neue"
          marginTop="100px"
          sx={{
            writingMode: "vertical-rl", // テキストを縦に配置
            background: 'linear-gradient(to right, #FFC857, #FFC857, #DCA7A7)', // グラデーションの背景
            WebkitBackgroundClip: "text", // 背景クリップをテキストに適用
            WebkitTextFillColor: "transparent", // グラデーションをテキストの色として適用
            display: "inline-block", // 背景クリップとグラデーションを適用するために必要
          }}
        >
          DAO Community
        </Text>
      </Box>
    </Flex>
  );
}

export default Top;
