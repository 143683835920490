import React, { useState } from 'react';
import { 
  Box, 
  Flex, 
  Text, 
  Image, 
  SimpleGrid,
  Center, 
  useDisclosure, 
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader, 
  ModalCloseButton, 
  ModalBody
 } from '@chakra-ui/react';
 import { useLanguage } from './LanguageContext';

//  メンバーデータの定義（コンポーネントの外部に配置）
 const membersData = [
  {
    name: "Saki Honda",
    role: "Co-Founder & Chief Visionary",
    imageSrc: "saki.png",
    vision: {
      JP:"「ホームスクール x tech教育」プロジェクトの設立と運営をしています / 未来の働き方をつくるビジョンを本にすべく執筆中。人生で大切にしていること... 個人的なライフテーマが「この社会の多様性を積み重ねていくこと」なので、今、新しい働き方や教育のカタチを創ったりと手を動かすことができていて、悩むこともあるけれど楽しいです。",
      EN:"I am founding and managing a 'Homeschool x Tech Education' project and am currently writing a book to shape a vision for the future of work. The thing I value most in life is 'building on the diversity of society,' which is my personal life theme. Right now, I am actively creating new forms of work and education, and while it can be challenging, it's also enjoyable.",
    },
    profile: {
      JP: "93年生まれ、小中高を海外で過ごす。慶應義塾大学卒業後、就職せずにフリーランスのライターを経て、B2B Makersに参加。2023年より共同代表。4歳の息子育て中。",
      EN: "Born in 1993, I spent my elementary, middle, and high school years abroad. After graduating from Keio University, I skipped traditional employment and became a freelance writer before joining B2B Makers. I have been a co-representative since 2023 and am currently raising a 4-year-old son.",
    }
  },
  // {
  //   name: "Tomoyo Shuto",
  //   role: "Chief Community",
  //   imageSrc: "shutomo.png",
  //   vision: {
  //     JP:"毎日のジャーナリングと読書の実践家であり、SNSで発信もしています。書く世界に魅力を感じて日々のタスク整理やビジョンボートなど手帳やノートを活用しています。ビジョンボードは8年、ノートや手帳活用は6年、バレットジャーナルや感情ジャーナリングなど様々なジャーナリング方法を組み合わせて自分自身とのコミュニケーションを大切にしています。",
  //     EN:"I am a daily practitioner of journaling and reading, and I also share my insights on social media. I am drawn to the world of writing and use planners and notebooks for daily task organization and vision boards. I have been using vision boards for eight years and planners for six years. I value communication with myself by combining various journaling methods, such as bullet journaling and emotional journaling.",
  //   },
  //   profile: {
  //     JP:"前職は女性のための美容の仕事をしていました。今は私自身男女3人の子どもを育てながら女性がもっと自由に働ける社会について考えています。",
  //     EN:"In my previous job, I worked in the beauty industry, focusing on services for women. Now, as I raise three children, two boys and a girl, I am thinking about how to create a society where women can work more freely.",
  //   },
  // },
  {
    name: "Ayaka Igeta",
    role: "Chief Public Relations",
    imageSrc: "ayaka.png",
    vision: {
      JP:"私のビジョンは、【人生という航海においてチャレンジする気持ちを忘れない。希望の帆を広げ、風を掴む】です。",
      EN:"My vision is to never forget the spirit of challenge on the voyage of life. To spread the sails of hope and catch the wind.",
    },
    profile: {
      JP:"大学卒業後は金融機関やベンチャー企業を経て結婚、出産し、現在は7歳と4歳の子育て中です。子どもたちが大人になる頃には、女性、特に母たちがさまざまな面で損せず、より生きやすく、より働きやすい社会を本気で作りたいと思っています。今、Web3の革新的なテクノロジーを用いてそれが可能かトライアンドエラーを繰り返しているところです。私たちは、それをやり遂げることが会社としての責任であって、私自身は当社の考え方やアクションを広く世の中の方に知っていただけるように動いていきたいと思っています。様々な方々にお力添えをいただければ幸いです。諦めずに取り組んでいきたいと思っています。",
      EN: "After graduating from university, I worked at financial institutions and venture companies, then got married and had children. I am currently raising a 7-year-old and a 4-year-old. By the time my children are adults, I truly want to create a society where women, especially mothers, can live and work more easily without disadvantage. Right now, I am experimenting with the innovative technology of Web3 to see if this is possible. It is our company’s responsibility to achieve this, and I personally want to promote our philosophy and actions to a wider audience. I would appreciate any support from various people and am committed to persevering in these efforts.",
    },
  },
  {
    name: "Daichi Sugahara",
    role: "Chief Technology",
    imageSrc: "daichi.png",
    vision: {
      JP:"私のビジョンは、Web3をはじめとする最新テクノロジーを活用し、革新的な働き方を創出することです。特に、自分と同じような若い世代や子育て中の家族が直面する挑戦に対応し、彼らがより柔軟で充実したライフスタイルを享受できるような一助となりたいと考えています。技術を通じて、働き方の未来を再定義し、次世代へのインスピレーションとなるような遺産を築いていく。それが私たちの約束であり、社会への責任です。",
      EN:"My vision is to leverage cutting-edge technologies, including Web3, to create innovative ways of working. Specifically, I aim to support young people and families with children—like myself—who face various challenges, helping them enjoy a more flexible and fulfilling lifestyle. Through technology, we will redefine the future of work and build a legacy that inspires future generations. This is our commitment and responsibility to society.",
    },
    profile: {
      JP:"こんにちは、菅原大地です。エンジニアとして、常に新しい技術を学び、その知識を仕事に活かすことに情熱を傾けています。ランニングや健康にも関心が高く、日々のウェルネスを追求することで心身のバランスを整えています。朝のランニングで最高な1日を始めることができます。また、アニメを観ることで想像力を膨らませ、クリエイティブな思考を刺激しています。",
      EN:"Hello, I'm Daichi Sugawara. As an engineer, I am constantly learning new technologies and passionately applying this knowledge to my work. I also have a strong interest in running and health, and I strive for daily wellness to maintain physical and mental balance. Starting the day with a morning run sets me up for a great day. Additionally, watching anime expands my imagination and stimulates creative thinking.",
  },
}
];
// Memberコンポーネントの定義
const Member = ({ name, role, imageSrc, onOpen }) => {
  return (
    <Flex direction="column" align="center" textAlign="center" mx="24px" zIndex="2" onClick={onOpen}>
      <Image src={imageSrc} alt={name} boxSize={["150px", "200px", "250px"]} borderRadius="full" objectFit="cover" mb="24px" cursor="pointer" />
      <Text fontSize="xl" fontWeight="bold" mb="4">{name}</Text>
      <Text fontSize="md">{role}</Text>
    </Flex>
  );
};

// Membersコンポーネントの定義
const Members = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedMember, setSelectedMember] = useState(null);
  const { language } = useLanguage(); // 言語状態を取得

  const handleOpenModal = (member) => {
    setSelectedMember({
      ...member,
      vision: member.vision[language], // 言語に応じたビジョンを選択
      profile: member.profile[language], // 言語に応じたプロフィールを選択
    });
    onOpen();
  };

  return (
    <Flex 
      direction="column" 
      align="center" 
      justify="center" 
      w="100vw"
      // h={{ base: "170vh", md: "100vh", lg: "100vh" }}
      height={["100%", "100%", "100%", "100%"]}
      bgImage="member.png" 
      bgSize="cover" 
      bgPos="center center" 
      p={["20px", "20px", "40px","0px"]} 
      position="relative"
      mt={["20px","100px","20px","80px"]}
    >

      <Box position="absolute"
       top={0} left={0} right={0} bottom={0} 
       bg="white" 
       opacity="0.9" 
       zIndex="2"
       >
       </Box>

      <Center 
      my={{ base: "20px", md: "40px" }} 
      mb={{ base: "20px", md: "80px" }}
      position="relative" 
      zIndex="3" 
      >
        <Text
        fontSize={{ base: "20px", sm:"25px",md: "32px", lg: "40px" }}
        fontFamily="Helvetica" 
        position="relative"
        marginBottom={["20px",""]}
        _after={{
                content: "''",
                position: 'absolute',
                display: 'block',
                height: '4px',
                width: '100%',
                bottom: '0',
                marginBottom: '-4px',
                background: 'linear-gradient(to right, #FFC857, #FFC857, #DCA7A7)',
              }}
              >
          Members
        </Text>
      </Center>

      <SimpleGrid columns={{ base: 1, sm:2, md: 2, lg: 3 }} 
       spacing="40px"
       position="relative"
       zIndex="4"
       >
        {membersData.map((member, index) => (
          <Box key={index} onClick={() => handleOpenModal(member)}>
            <Member {...member} onOpen={() => handleOpenModal(member)} />
          </Box>
        ))}
      </SimpleGrid> 

      {selectedMember && (
        <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader fontSize="24px" display="flex" justifyContent="center" alignItems="center">
              {selectedMember.name}
            </ModalHeader>
            <ModalCloseButton position="absolute" right="8px" top="8px" />
            <ModalBody p={6}>
            <Flex direction="column" align="center" mb={6}>
             <Image borderRadius="full" boxSize="240px" src={selectedMember.imageSrc} alt={selectedMember.name} objectFit="cover"/>
             <Text mt={6} fontSize="lg" fontWeight="bold">{selectedMember.role}</Text>
             </Flex> 
            <Text fontSize="md" fontWeight="semibold" mb={2}>My vision</Text>
            <Text fontSize="md" mb={4}>{selectedMember.vision}</Text>
            <Text fontSize="md" fontWeight="semibold" mb={2}>Profile</Text>
            <Text fontSize="md">{selectedMember.profile}</Text>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Flex>
  );
};

export default Members;

