import React from 'react';
import { Box, Flex, Image, IconButton, Menu, MenuButton, MenuItem, MenuList, useBreakpointValue } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Link, useNavigate } from 'react-router-dom';
import { useLanguage } from './LanguageContext';

function Header() {
  const isMobile = useBreakpointValue({ base: true, md: true, lg: false });
  const navigate = useNavigate();
  const { language, toggleLanguage } = useLanguage(); 

  const handleLanguageToggle = () => {
    toggleLanguage();
    // ナビゲーションやページリロードの必要があればここで処理
  };

  const navigateHome = () => {
    navigate('/#top');
  };

  return (
    <Flex
      bg="rgba(255, 255, 255, 0.85)"
      color="#151515"
      align="center"
      justify="space-between"
      pt={["15px", "30px"]}
      height="80px"
      marginBottom="10px"
      position="fixed"
      top="0"
      left="0"
      right="0"
      zIndex="1000"
      boxShadow="sm"
      width="100%"
    >
       <Box 
        ml={["10px","20px","20px","50px"]} 
        onClick={navigateHome} 
        style={{ cursor: 'pointer' }}
       >
        <Image 
          height={["40px","48px","55px","65px"]} 
          marginBottom={["","20px"]} 
          src="logo_header.png" 
          alt="B2B Makers Logo"
        />
      </Box>  

      {isMobile ? (
         <Flex align="center">
          <IconButton
              aria-label="Toggle Language"
              onClick={handleLanguageToggle}
              icon={<Image src={language === 'JP' ? "/uk_flag.png" : "/japan_flag.png"} alt="Toggle Language" boxSize="20px" objectFit="cover"/>}
              variant="ghost"
              size="sm"
              mr="12px"
            />
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<HamburgerIcon />}
              variant="outline"
              marginRight="16px"
            />
            <MenuList>
              <MenuItem as={Link} to="/#top">Home</MenuItem>
              <MenuItem as={Link} to="/#mission">Mission</MenuItem>
              <MenuItem as={Link} to="/#platform">Platform/Project</MenuItem>
              <MenuItem as={Link} to="/#member">Members</MenuItem>
              <MenuItem as={Link} to="/company">Company</MenuItem>
              <MenuItem as={Link} to="/#contact">Contact</MenuItem>
              <MenuItem onClick={handleLanguageToggle}>
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      ) : (
        <Box flex={1} mr="16px" textAlign="right">
          <Link to="/#top" style={{ marginRight: '24px' }}>Home</Link>
          <Link to="/#mission" style={{ marginRight: '24px' }}>Mission</Link>
          <Link to="/#platform" style={{ marginRight: '24px' }}>Platform/Project</Link>
          <Link to="/#member" style={{ marginRight: '24px' }}>Members</Link>
          <Link to="/company" style={{ marginRight: '24px', textDecoration: 'none', color: 'inherit' }}>Company</Link>
          <Link to="/#contact" style={{ marginRight: '24px' }}>Contact</Link>
          <IconButton
            aria-label="Toggle Language"
            onClick={handleLanguageToggle}
            icon={<Image src={language === 'JP' ? "/uk_flag.png" : "/japan_flag.png"} alt="Toggle Language" boxSize="20px" objectFit="cover" />}
            variant="ghost"
            size="sm"
            mr="16px"
          />
        </Box>
      )}
    </Flex>
  );
}

export default Header;


