import React from 'react';
import { Box, VStack, Image,useColorModeValue ,useBreakpointValue, Text} from '@chakra-ui/react';
import HometechMessage from "./HometechMessage";
import HometechConcept from "./HometechConcept";
import { FaArrowDownLong } from "react-icons/fa6";

function HomeTechHome() {
  const iconColor = useColorModeValue('white', 'white');
  const iconSize = useBreakpointValue({ base: "16px", md: "24px", lg: "32px" });

  return (
    <VStack spacing={4}align="stretch" marginTop={["85px","85px"]} marginBottom="35px"> 
      <Box position="relative" width="full">
        <Image src="hometechtop.png" alt="ヘッダーイメージ" objectFit="cover" width="full" />
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bgColor="#181233"
          opacity="0.7"
          width="full"
          height="full"
        />
        <Box
          position="absolute"
          top={{ base: "40%", md: "40%", lg: "30%" }}
          left="50%"
          transform="translate(-50%, -50%)" /* 中央揃えの調整 */
          zIndex="2" /* オーバーレイよりも前面に表示 */
          boxSize={{ base: "60%", md: "60%", lg: "50%" }} 
        >
          <Image src="hometech_top_title.png" alt="ヘッダーイメージタイトル" objectFit="cover" width="full" />
        </Box>
        <Box
          position="absolute"
          left="50%"
          bottom="0px"  // 要素の下端を親要素の下端と一致させる
          transform="translateX(-50%)"  // X軸方向に要素の幅の50%だけ左に移動
          zIndex="2"
        >
          <FaArrowDownLong color={iconColor}  size={iconSize}/>
          <Text fontSize={["10px","16px"]} ml={["-3px","-1px"]} color={iconColor}>scroll</Text>  

        </Box>
      </Box>
      <HometechMessage/>
      <HometechConcept/>
    </VStack>
  );
}

export default HomeTechHome;
