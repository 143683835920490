import { Box, Center, Text, Image } from '@chakra-ui/react';
import { useLanguage } from './LanguageContext';
import { useNavigate } from 'react-router-dom';

function Community() {
  const navigate = useNavigate();
  const { language } = useLanguage();

  const handleHometechNavigation = () => {
    navigate('/hometech');
    window.scrollTo(0, 0);
  };

  const handleInnerMapJournalNavigation = () => {
    navigate('/inner-map-journal');
    window.scrollTo(0, 0);
  };

  return (
    
      <Box
      width={{ base: "100%", sm: "80%", md: "80%" }}
      minH={{ base: "100%", sm: "80%", md: "80%" }} 
      marginX="auto"
      bg="#5ABCB9"
      position="relative"
      zIndex="1"
      p={{ base: "16px", md: "32px" }}
      >
        <Box
          position="relative"
          width="full"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {/* Community Text */}
          <Center my={{ base: "20px", md: "40px" }}>
            <Text
              fontSize={{ base: "20px", sm:"25px", md: "30px", lg: "40px" }}
              fontFamily="'Helvetica'"
              position="relative"
              color="white"
              mt={["20px","40px","40px","80px"]}
              textAlign="center"
              _after={{
                content: "''",
                position: 'relative',
                display: 'block',
                height: '4px',
                width: '100%',
                mt: '0px',
                marginBottom: '-4px',
                background: 'linear-gradient(to right, #FFC857, #FFC857, #DCA7A7)',
              }}
            >
              Community
            </Text>
          </Center>

          <Center w="full" my={{ base: "20px", md: "40px" }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              // flexWrap="wrap"
              gap="20px"
              w="full"
              flexDirection={["column","row","row","row"]}
            >
              <Box 
                width={{ base: "100%", md: "40%" }} 
                height={["400px","320px", "320px", "400px"]}
                px={["8","4",""]}
                pt={["8","6",""]}
                pb={["8","6",""]}
                bg="white" 
                borderRadius="lg" 
                shadow="md" 
                m="4"
                onClick={handleHometechNavigation}
                cursor="pointer"
              >
                    <Image 
                      src="homeschool.png" 
                      alt="homeschool" 
                      borderRadius="md" 
                      height={["200px","100px","110px","200px"]}
                    />
                    <Text
                      mt={["8",""]}
                      fontSize={["20px","16px","16px","20px"]}
                      fontWeight="bold"
                    >
                      Homeschool x Tech Education
                    </Text>
                    {language === 'JP' ? (
                    <Text 
                      mt="2"
                      fontSize={["16px","14px","14px","16px"]}
                    >
                      Homeschoolでテック教育をサポートするアプリケーション。
                    </Text>
                    ):(
                      <Text 
                        mt="2"
                        fontSize={["16px","14px","14px","16px"]}
                      >
                        An application to support tech education in homeschooling.
                      </Text>
                    )}
                </Box>
        
                <Box 
                  w={{ base: "100%", md: "40%" }} 
                  height={["400px","320px", "320px", "400px"]}
                  px={["8","4",""]}
                  pt={["8","6",""]}
                  pb={["8","6",""]}
                  bg="white" 
                  borderRadius="lg" 
                  shadow="md" 
                  m="4"
                  onClick={handleInnerMapJournalNavigation}
                  cursor="pointer"
                >
                      <Image 
                        src="IMG_1290.png" 
                        alt="inner journal" 
                        borderRadius="md" 
                        width={["190px","95px","105px","190px"]}
                        height={["200px","100px","110px","200px"]}
                        marginLeft={["30px","40px","40px","30px"]}
                      />
                      <Text 
                        mt={["8",""]} 
                        fontSize="lg" 
                        fontWeight="bold"
                      >
                        Inner Map Journal
                      </Text>
                      {language === 'JP' ? (
                        <Text 
                          mt="2"
                        >
                          自己理解を深めるジャーナリングモバイルアプリケーション
                        </Text>
                      ):(
                        <Text 
                          mt="2"
                          fontSize={["16px","14px","14px","16px"]}
                          >
                          A journaling mobile application to deepen self-understanding
                        </Text>
                      )}
                </Box>
            </Box>
          </Center>
        </Box>
      </Box>
  );
}

export default Community;
