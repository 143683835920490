// src/contexts/LanguageContext.js
import React, { createContext, useContext, useState } from 'react';

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState(() => localStorage.getItem('language') || 'JP');
  
    const toggleLanguage = () => {
      setLanguage((lang) => {
        const newLang = lang === 'JP' ? 'EN' : 'JP';
        localStorage.setItem('language', newLang);
        return newLang;
      });
    };
  
    return (
      <LanguageContext.Provider value={{ language, toggleLanguage }}>
        {children}
      </LanguageContext.Provider>
    );
  };
  
