import React from 'react';
import { Box, Flex, Text, Link as ChakraLink, Spacer } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useLanguage } from './LanguageContext';

function Footer() {
  const { language } = useLanguage();
  return (
    <Flex
      bg="rgba(255, 255, 255, 0.85)"
      color="#151515"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      p={{ base: "5px", md: "20px" }}
      minheight={["20px","100px"]}
      marginTop="85px"
      position="fixed"
      bottom="0" // 上端から0pxの位置に
      left="0" // 左端から0pxの位置に
      right="0" // 右端から0pxの位置に
      zIndex="1000" // Zインデックスを高くして他のコンテンツより前面に表示
      boxShadow="sm" // 影を少し加える
      width="100%" // 幅は100%
    >
       <Box textAlign="center" w="full" display={{ base: "none", md: "block" }}>
       <ChakraLink as={Link} to="/#top" mr="24px">Home</ChakraLink>
        <ChakraLink as={Link} to="/#mission" mr="24px">Mission</ChakraLink>
        <ChakraLink as={Link} to="/#platform" mr="24px">Platform/Project</ChakraLink>
        <ChakraLink as={Link} to="/#member" mr="24px">Members</ChakraLink>
        <ChakraLink as={Link} to="/company" mr="24px">Company</ChakraLink>
        <ChakraLink as={Link} to="/#contact" mr="24px">Contact</ChakraLink>
        {/* <Link href="/" mr="16px" fontFamily="Helvetica" fontWeight="bold">EN</Link>
        <Link href="/" fontFamily="Helvetica" fontWeight="bold">JA</Link> */}
      </Box>

      {language === 'JP' ? (
        <Text fontSize="14px" pl={{ base: "14px", md: "16px" }} mt="4" mb="16px">
           © 2023 株式会社 B2B Makers
         </Text>
      ):(
        <Text fontSize="14px" pl={{ base: "14px", md: "16px" }} mt="4" mb="16px">
          © 2023 B2Bmakers Co.,Ltd 
        </Text>
      )}
      <Spacer />
    </Flex>
  );
}

export default Footer;
