// import React, { useEffect } from 'react';
// import 'youtube-video-js';
// import { useBreakpointValue } from '@chakra-ui/react';

// function YouTubeVideo({ videoId }) {
// 　　const videoWidth = useBreakpointValue({ base: "300", sm:"380",md: "420",lg:"640"});
// 　　const videoHeight = useBreakpointValue({ base: "168",sm:"214", md: "235",lg:"360"});

// console.log(videoHeight)
  
//   useEffect(() => {
//     const videoElement = document.querySelector('youtube-video');
//     if (videoElement) {
//       videoElement.load().then(() => {
//       });
//     }
//   }, [videoId]);

//   return (
//     <youtube-video
//     　width={videoWidth}
//       height={videoHeight}
//       src={`https://www.youtube.com/watch?v=${videoId}`}
//     //   autoplay
//       controls
//       style={{ display: 'block', top:0, left:0, margin: '0 auto' }} 
//     />
//   );
// }

// export default YouTubeVideo;

import React from 'react';
import { useBreakpointValue, Box } from '@chakra-ui/react';

const YouTubeVideo = ({ videoId }) => {
  const videoWidth = useBreakpointValue({ base: "300px", sm: "380px", md: "420px", lg: "640px" });
  const videoHeight = useBreakpointValue({ base: "168px", sm: "214px", md: "235px", lg: "360px" });

  return (
    <Box 
      position="relative" 
      paddingBottom="56.25%" 
      overflow="hidden" 
      maxWidth="100%" 
      background="#000"
      width={videoWidth}
      height={videoHeight}
    >
      <iframe
        title="YouTube video"
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Box>
  );
}

export default YouTubeVideo;
