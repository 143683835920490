import React from 'react';
import { Box, Text, VStack, Center } from '@chakra-ui/react';
import { useLanguage } from '../LanguageContext'; // ここで言語設定をインポート

function HometechMessage() {
  const { language } = useLanguage(); // 言語の状態を取得

  return (
    <Box p="0 40px">
      <VStack spacing={4}>
        <Center>
          <Text marginTop={["20px", "60px"]} marginBottom={["20px", "40px"]}fontSize={["36px","48pt"]} fontFamily="'Henny Penny', cursive">
            Message
          </Text>
        </Center>
        <Box maxWidth="800px" textAlign="left" margin="0 auto">
          <Text fontFamily="'Zen Maru Gothic', sans-serif" fontSize={["16px","xl"]} mb="4">
            {language === 'EN' ? 
              `My child stopped going to nursery school.
               "What if they become a truant in the future?"
               I was almost crushed by such anxiety.` :
              `子どもが保育園に行かなくなった。
               「この先、不登校になったらどうしよう」
               そんな不安に押しつぶされそうになった。`}
          </Text>
          {/* その他のテキストも同様に言語に応じて設定 */}
          <Text fontFamily="'Zen Maru Gothic', sans-serif" fontSize={["16px","xl"]} mb="4">
            {language === 'EN' ?
              `But, watching my child play happily at home, I wonder.
               Do we need to go to school to learn?
               Can't we become proper adults without school?
               ...Really, is that so?
               No, it's not true.` :
              `でも、と、家で楽しく遊ぶ子どもを見て思う。
               学校に行かなきゃ学べない。
               学校に行かなきゃ、ちゃんとした大人になれない。
               ...ほんとうに、そうなのかな。
               いや、そんなことないよな。`}
          </Text>
          <Text fontFamily="'Zen Maru Gothic', sans-serif" fontSize={["16px","xl"]} mb="4">
            {language === 'EN' ? 
              "At home, at school, anywhere, if there's curiosity and interest, learning has already begun." :
              "家でだって学校でだってどこでだって、「知りたい」「これって何だろう」という好奇心と興味関心があれば、学びが始まっている。"}
          </Text>
          <Text fontFamily="'Zen Maru Gothic', sans-serif" fontSize={["16px","xl"]} mb="4">
            {language === 'EN' ?
              "Watching my 3-year-old son bombard ChatGPT with 'why?' questions and enjoy videos on YouTube, I realize I'm learning from my child. The lessons I can teach are limited, but I want to learn together using technology." :
              "Youtubeで音声を楽しむし、ChatGPTに「なんで？」の質問をしまくる3歳の息子を見ながら、子どもに学ぶのはむしろ自分の方だし、教えられることは限られているけど、テクノロジーを使って、一緒に学びたいと思った。"}
          </Text>
          <Text fontFamily="'Zen Maru Gothic', sans-serif" fontSize={["16px","xl"]} mb="4">
            {language === 'EN' ?
              "Together with my child, leveraging technology, can we create a flexible learning environment based on their curiosity, even from home?" :
              "もっと親子で一緒に、テクノロジーを活用しながら、家にいながらでも、子どもの好奇心に基づいた柔軟な学びのカタチ、つくれないかな。"}
          </Text>
          <Text fontFamily="'Zen Maru Gothic', sans-serif" fontSize={["16px","xl"]} mb="8">
            {language === 'EN' ?
              "That's what inspired me to start this project. Will you join us in shaping the future of education?" :
              "そう思って、始めたプロジェクトです。少し未来の教育の力を、一緒につくりませんか。"}
          </Text>
          <Text fontFamily="'Zen Maru Gothic', sans-serif" fontSize={["16px","xl"]} mb="4">
            {language === 'EN' ? 
              "Saki Honda CEO" :
              "Saki Honda 代表取締役"}
          </Text>
        </Box>
      </VStack>
    </Box>
  );
}

export default HometechMessage;
