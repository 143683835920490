import React, { useEffect,useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  useBreakpointValue,
  VStack,
  Flex, 
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom'; 
import { useLanguage } from './LanguageContext'; 
import axios from 'axios';
import awsExports from './aws-exports';


const ContactForm = () => {
  const navigate = useNavigate();
  const { language, toggleLanguage } = useLanguage(); 
  const buttonSize = useBreakpointValue({ base: 'md', md: 'lg' });
  const [formData, setFormData] = useState({
    companyName: '',
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [isSuccess, setIsSuccess] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };


  const handleSubmit = async (event) => {
    console.log(formData);
    event.preventDefault();
    const apiUrl = 'https://7c864vlid9.execute-api.ap-northeast-1.amazonaws.com/staging/sendEmail';
    const { companyName, name, email, phone, message } = formData;
    const payload = {
      companyName: companyName || '', 
      name: name || '',
      email: email || '',
      phone: phone || '',
      message: message || ''
  };

  const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
  };
    try {
        const response = await fetch(apiUrl, requestOptions);
        const responseData = await response.json();
        console.log('Email sent:', responseData);
        if (response.status === 200) {
          setIsSuccess(true);
          setModalContent(language === 'JP' ? 'お問い合わせありがとうございます。\n送信を受け取りました。\nお問い合わせの内容は下記の通りです。\n\n会社名：' + formData.companyName + '\nお名前: ' + formData.name + '\nメール: ' + formData.email + '\nお問い合わせ内容:' + formData.message + '\n\n数日以内にこちらからご連絡を致します。しばらくお待ち願います。' : 'Thank you for your inquiry.\nWe have received your message.\nThe details of your inquiry are as follows:\n\nCompany Name: ' + formData.companyName + '\nName: ' + formData.name + '\nEmail: ' + formData.email + '\nInquiry: ' + formData.message + '\n\nWe will contact you within a few days. Please wait for a while.');
          setModalOpen(true);
          setTimeout(() => {
            setModalOpen(false); // モーダルを閉じる
            navigate('/'); // ホームページにリダイレクト
          }, 10000); 
        }
    } catch (error) {
        console.error('Error sending email:', error);
        setIsSuccess(false);
        setModalContent(language === 'JP' ? '誠に申し訳ございませんが、送信が上手く行きませんでした。\nもう一度お試しいただくか、下記のメールアドレスまでご連絡いただけますでしょうか？\ninfo@b2bmakers.jp' : 'We are very sorry, but the transmission was not successful.\nPlease try again or contact the following email address:\ninfo@b2bmakers.jp');
        setModalOpen(true);
    }
};


  useEffect(() => {
    // コンポーネントのマウント後に実行
    window.scrollTo({
      top: 20, // 
      behavior: 'smooth' // スムーズスクロール
    });
  }, []);
  
return (
  <Flex 
    direction="column" 
    align="center" 
    justify="center" p={{ base: "4", md: "8", lg: "12" }} 
    minH="100vh" 
    bg="#5ABCB9"
    marginTop={["25px","30px"]}
    marginBottom={["60px","85px"]}
    >

      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
          {isSuccess
            ? (language === 'JP' ? '送信成功' : 'Sent Successfully')
            : (language === 'JP' ? '送信失敗' : 'Failed to Send')}
            </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <pre style={{ 
                    whiteSpace: 'pre-wrap', 
                    fontSize: '14px',
                    fontFamily:"'Helvetica'",
                    color: '#151515',
                    lineHeight: '1.5'
                    }}>
              {modalContent}
            </pre>
          </ModalBody>
        </ModalContent>
      </Modal>
      
    <Text
      fontSize={{ base: "24px", md: "32px", lg: "32px" }}
      fontFamily="Helvetica"
      fontWeight="bold"
      color="white" 
      mb={["16px", "20px", "40px"]}
      position="relative">
        Contact
    </Text>

    <Text color="white" mb="4">
      {language === 'JP' ? 'どうぞお気軽にお問い合わせください！' : 'Feel free to contact us!'}
    </Text>

    <Box  p={4}>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
        <FormControl id="companyname">
          <Flex align="center">
            <FormLabel color="white" fontWeight="bold">
               {language === 'JP' ? '会社名' : 'Company Name'} <Text as="span" color="#FFC857">*</Text>
            </FormLabel>
          </Flex>
          <Input
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            type="text" 
            bg="white" 
          />
        </FormControl>
        <FormControl id="name">
          <Flex align="center">
            <FormLabel color="white" fontWeight="bold">
                {language === 'JP' ? 'お名前' : 'Name'} <Text as="span" color="#FFC857">*</Text>
            </FormLabel>
          </Flex>
          <Input
            name="name"
            value={formData.name}
            onChange={handleChange}
            type="text" 
            bg="white" 
          />
        </FormControl>

          <FormControl id="email">
          <FormLabel color="white" fontWeight="bold">
            {language === 'JP' ? 'メールアドレス' : 'e-mail'} <Text as="span" color="#FFC857">*</Text>
            </FormLabel>
            <Input 
              name="email"
              value={formData.email}
              onChange={handleChange}
              type="email" 
              bg="white" />
          </FormControl>

          {/* <FormControl id="phone">
            <FormLabel color="white" fontWeight="bold">
            {language === 'JP' ? '電話番号' : 'Phone'}  <Text as="span" color="#FFC857">*</Text>
            </FormLabel>
            <Input 
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              type="tel" 
              bg="white"/>
          </FormControl> */}

          <FormControl id="message">
            <FormLabel color="white" fontWeight="bold">
            {language === 'JP' ? 'お問い合わせ内容' : 'Inquiry'}  <Text as="span" color="#FFC857">*</Text>
              </FormLabel>
            <Textarea 
              name="message"
              value={formData.message}
              onChange={handleChange}
              bg="white"/>
          </FormControl>


         {/* <Box bg="white" p={4} shadow="md" overflowY="auto" maxH="300px">
          <Textarea placeholder="プライバシーポリシー" isReadOnly  />
          <Text mt={4}>
            ここにプライバシーポリシーが入ります。
          </Text>
         </Box> */}

        {/* <FormControl>
          <Checkbox color="white" fontWeight="bold" colorScheme="teal">
          <Text color="white" fontWeight="bold">プライバシーポリシーに同意する</Text>
          </Checkbox>
        </FormControl> */}

          <Button size={buttonSize} colorScheme="teal" bg="#FFC857" type="submit" >
            {language === 'JP' ? 'お問い合わせを送信する' : 'Send Inquiry'}
          </Button>
        </VStack>
      </form>
    </Box>
  </Flex>
  );
};

export default ContactForm;
