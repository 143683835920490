import { Box, Text} from '@chakra-ui/react';
import { useLanguage } from '../LanguageContext';

function LegalStatus() {
  const { language } = useLanguage();

  return (
    <Box p={5}  marginTop="250px" textAlign="center">
        <Text fontSize={["18px","24px"]} color="#5ABCB9" fontWeight="bold"  marginTop={["36px","48px"]}>
             {language === 'JP' ? '制作中です' : 'Under Construction'}
        </Text>
    </Box>
  );
}

export default LegalStatus;