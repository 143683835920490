import { ChakraProvider } from "@chakra-ui/react";
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToAnchor from './ScrollToAnchor';
import Header from './Header';
import Top from './Top';
import Mission from './Mission';
import Platform from './Platform';
import Community from './Community';
import Member from './Member';
import Contact from './Contact';
import Company from './Company';
import ContactForm from './ContactForm';
import Footer from './Footer';
import HomeTechHome from "./HomeTech/HomeTechHome";
import JournalHome from "./InnerMapJournal/JournalHome";
import IdeaFlowBankHome from "./IdeaFlowBank/IdeaFlowBankHome";
import LegalStatus from "./IdeaFlowBank/LegalStatus";
import Articles from "./Article/Articles";
import ArticleUpload from "./Article/ArticleUpload";
import ArticlePage from "./Article/ArticlePage";

function App() {
  return (
    <ChakraProvider>
      <Router>
        <ScrollToAnchor />
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <div id="top"><Top /></div>
              <div id="mission"><Mission /></div>
              <div id="platform"><Platform /></div>
              <div id="community"><Community /></div>
              <div id="artcles"><Articles/></div>
              <div id="member"><Member/></div>
              <div id="contact"><Contact /></div>
            </>
          } />
          <Route path="/company" element={<Company />} />
          <Route path="/contact-form" element={<ContactForm />} />
          <Route path="/hometech" element={<HomeTechHome />} />
          <Route path="/inner-map-journal" element={<JournalHome />} />
          <Route path="/ideaflowbank-home" element={<IdeaFlowBankHome />} />
          <Route path="/articles" element={<Articles/>} />
          <Route path="/article-upload" element={<ArticleUpload/>} />
          <Route path="/legal-status" element={<LegalStatus />} />
          <Route path="/article-page/:id" element={<ArticlePage />} />
        </Routes>
        <Footer />
      </Router>
    </ChakraProvider>
  );
}

export default App;

