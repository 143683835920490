import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Box, Text} from '@chakra-ui/react';

function HomeTechHome() {
  useEffect(() => {
    AOS.init({ duration: 2000 });  // アニメーションの期間を2000ミリ秒（2秒）に設定
  }, []);

  return (
    <Box
      data-aos="flip-right"
      height="100vh"  // ビューポートの高さを全体に設定
      width="100vw"   // ビューポートの幅を全体に設定
      display="flex"  // フレックスボックスを使用
      alignItems="center"  // 子要素を垂直方向の中央に配置
      justifyContent="center"  // 子要素を水平方向の中央に配置
    >
      <Text 
        fontSize={["30px","70px"]} 
        fontWeight="bold"
        color="#151515"
      >
        Coming Soon
      </Text>
    </Box>
  );
}

export default HomeTechHome;