// import React from 'react';
// import { Box, Text, SimpleGrid, Image, VStack, useMediaQuery, Center } from '@chakra-ui/react';

// function HometechConcept() {
//   // レスポンシブ対応のためのメディアクエリ
//   const [isMobile] = useMediaQuery("(max-width: 768px)");

//   return (
//     <Box textAlign="center" color="#181233" pt="100px" pb="100px">
//       {/* Conceptタイトル */}
//       <Text fontSize="48pt" fontFamily="'Henny Penny', cursive" mb="100px">
//         Concept
//       </Text>
//       {/* テキストを含むBox */}
//       <Box maxWidth="800px" textAlign="left" m="0 auto" mb="8">
//         <Text fontSize="xl" fontFamily="'Zen Maru Gothic', sans-serif">
//           1. 大人も子どもも関係なく、遊び（これやりたい、という興味関心）の中から学ぶ。<br />
//           2. 感覚、学び、他者理解(共感/対話)の一体化
//         </Text>
//       </Box>
//       {/* 画像と説明テキストを含むBox */}
//       <Center mb="8">
//         <Box maxWidth="1000px" width="100%">
//           <SimpleGrid columns={isMobile ? 1 : 3} spacing="40px">
//             <VStack>
//               <Image src="hometech_programming.png" boxSize="300px" alt="プログラミング工作" />
//               <Text fontSize="xl" fontFamily="'Zen Maru Gothic', sans-serif">プログラミング工作</Text>
//             </VStack>
//             <VStack>
//               <Image src="hometech_gokko_play.png" boxSize="300px" alt="ロボットとごっこ遊び" />
//               <Text fontSize="xl" fontFamily="'Zen Maru Gothic', sans-serif">ロボットとごっこ遊び</Text>
//             </VStack>
//             <VStack>
//               <Image src="hometech_ai.png" boxSize="300px" alt="生成AIペット" />
//               <Text fontSize="xl" fontFamily="'Zen Maru Gothic', sans-serif">生成AIペット</Text>
//             </VStack>
//           </SimpleGrid>
//         </Box>
//       </Center>
//     </Box>
//   );
// }

// export default HometechConcept;


import React from 'react';
import { Box, Text, SimpleGrid, Image, VStack, useMediaQuery, Center } from '@chakra-ui/react';
import { useLanguage } from '../LanguageContext'; // 言語設定のインポート

function HometechConcept() {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const { language } = useLanguage(); // 言語の状態を取得

  return (
    <Box textAlign="center" color="#181233" pt="100px" pb="100px" p="0 40px" marginBottom={["40px","80px"]}>
      <Text marginTop={["20px", "60px"]} marginBottom={["20px", "40px"]}fontSize={["36px","48pt"]} fontFamily="'Henny Penny', cursive">
        Concept
      </Text>
      <Box maxWidth="800px" textAlign="left" m="0 auto" mb="8">
        <Text fontFamily="'Zen Maru Gothic', sans-serif" fontSize={["16px","xl"]} mb="4">
          {language === 'EN' ?
            `1. Learning emerges from play (interests and desires) regardless of age.` :
            `1. 大人も子どもも関係なく、遊び（これやりたい、という興味関心）の中から学ぶ。`}
        </Text>
        <Text fontFamily="'Zen Maru Gothic', sans-serif" fontSize={["16px","xl"]} mb="4">
          {language === 'EN' ?
            `2. Integration of sensation, learning, and understanding others (empathy/dialogue).` :
            `2. 感覚、学び、他者理解(共感/対話)の一体化`}
        </Text>
      </Box>
      <Center mb="8">
        <Box maxWidth="1000px" width="100%">
          <SimpleGrid columns={isMobile ? 1 : 3} spacing="40px">
            <VStack>
              <Image src="hometech_programming.png" boxSize="300px" alt="Programming Craft" />
              <Text fontSize={["16px","xl"]} fontFamily="'Zen Maru Gothic', sans-serif">
                {language === 'EN' ? 'Programming Craft' : 'プログラミング工作'}
              </Text>
            </VStack>
            <VStack>
              <Image src="hometech_gokko_play.png" boxSize="300px" alt="Robot Play" />
              <Text fontSize={["16px","xl"]} fontFamily="'Zen Maru Gothic', sans-serif">
                {language === 'EN' ? 'Robot Play' : 'ロボットとごっこ遊び'}
              </Text>
            </VStack>
            <VStack>
              <Image src="hometech_ai.png" boxSize="300px" alt="Generated AI Pet" />
              <Text fontSize={["16px","xl"]} fontFamily="'Zen Maru Gothic', sans-serif">
                {language === 'EN' ? 'Generated AI Pet' : '生成AIペット'}
              </Text>
            </VStack>
          </SimpleGrid>
        </Box>
      </Center>
    </Box>
  );
}

export default HometechConcept;

