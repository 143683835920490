import React,{useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // AOS styles
import { Box, Text, Button, Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const Contact = () => {
  const navigate = useNavigate(); 

    // ボタンクリック時に実行される関数
    const goToContactForm = () => {
      navigate('/contact-form');  // /contact-formに遷移
    };

    useEffect(() => {
      // AOSを初期化し、特定の画面幅で無効にする
      AOS.init({
        disable: window.innerWidth < 640
      });
  
      // リサイズイベントに応じて再評価
      function handleResize() {
        AOS.refresh();
      }
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

  return (
    <Flex
      w="100vw" // コンテナの幅をビューポートの100%にします。
      h={["40vh", "300px", "100%", "80vh"]} // 異なるビューポートサイズでの高さを配列で指定します。
      bg="#000000" 
      bgImage="top_contact.jpg"
      bgSize="cover"
      bgPos="center center"
      align="center"
      justify="center"
      position="relative"
      marginTop={["60px","60px","40px","85px"]}
      marginBottom={["60px","150px"]}
    >
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        bgColor="rgba(0, 0, 0, 0.5)" // オーバーレイの色と不透明度を指定
        zIndex="1" // オーバーレイを背景とテキストの間に配置
      />
        <Box 
          p={["20px", "20px", "40px"]} 
          textAlign="center" 
          zIndex="2"
          marginTop={["0px","0px","0px","0px"]}
        >
        <Text
          fontSize={{ base: "20px", sm:"25px",md: "30px", lg: "40px" }}
          fontFamily="Helvetica"
          color="white"
          mt={["0px","0px","0px","0px"]}
          marginBottom={["20px",""]}
          textAlign="center"
          position="relative" 
          display="inline-block" 
          _after={{
            content: "''",
            position: 'relative',
            display: 'block',
            height: '4px',
            width: '100%',
            mt: '0px',
            marginBottom: '-4px',
            background: 'linear-gradient(to right, #FFC857, #FFC857, #DCA7A7)',
          }}
        >
          Contact us
        </Text>

        <Text 
          fontSize={{ base: "16px", md: "20px", lg: "32px" }}
          color="#FFF" 
          mb={["20px", "24px", "80px"]}
          zIndex="2">
            {/* お気軽にご連絡ください！ */}
        </Text>

        <Button
          onClick={goToContactForm} 
          fontSize={{ base: "16px", md: "20px", lg: "24px" }} 
          fontFamily="Helvetica"
          data-aos="flip-left"
          color="white"
          bg="#FFC857"
          p={["10px", "10px", "20px"]}
          _hover={{ bg: "#e6b347" }}
          w={{ base: "200px", sm: "200px", md: "280px" }} // ボタンの幅をレスポンシブに
          h={{ base: "32px", md: "48px" }} // ボタンの高さをレスポンシブに
          borderRadius="20px"
          border="2px solid white"
        >
          Click here
        </Button>
      </Box>
    </Flex>
  );
};

export default Contact;
