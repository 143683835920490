import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Input, VStack, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, Text } from '@chakra-ui/react';
import { generateClient } from 'aws-amplify/api';
import { createArticles, updateArticles, deleteArticles } from '../graphql/mutations';
import { listArticles } from '../graphql/queries';
import { list, uploadData } from 'aws-amplify/storage';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-quill/dist/quill.snow.css';
import ReactQuill, { Quill } from 'react-quill';

const client = generateClient();

// カスタムフォントスタイルオプションを定義
const Font = Quill.import('formats/font');
Font.whitelist = ['sans-serif']; // デフォルトのフォントリストに戻す
Quill.register(Font, true);

const ArticleUpload = () => {
  const [title, setTitle] = useState('');
  const [date, setDate] = useState(new Date());
  const [photoName, setPhotoName] = useState('');
  const [article, setArticle] = useState('');
  const [slideId, setSlideId] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchMaxSlideId = async () => {
      try {
        const result = await client.graphql({
          query: listArticles
        });
        const articles = result.data.listArticles.items;
        const maxSlideId = articles.reduce((max, article) => (article.SlideId > max ? article.SlideId : max), 0);
        setSlideId(maxSlideId + 1);
      } catch (error) {
        console.error('Error fetching max slideId:', error);
      }
    };
    fetchMaxSlideId();
  }, []);

  const handleUpload = async () => {
    try {
      const input = { Title: title, Date: date, PhotoName: photoName, Article: article, SlideId: parseInt(slideId) };
      await client.graphql({
        query: createArticles,
        variables: { input }
      });
      alert('Article uploaded successfully!');
    } catch (error) {
      console.error('Error uploading article:', error);
      alert('Failed to upload article');
    }
  };

  const handleUpdate = async () => {
    try {
      const input = { Title: title, Date: date, PhotoName: photoName, Article: article, SlideId: parseInt(slideId) };
      await client.graphql({
        query: updateArticles,
        variables: { input }
      });
      alert('Article updated successfully!');
    } catch (error) {
      console.error('Error updating article:', error);
      alert('Failed to update article');
    }
  };

  const handleDelete = async () => {
    try {
      const input = { SlideId: parseInt(slideId) };
      await client.graphql({
        query: deleteArticles,
        variables: { input }
      });
      alert('Article deleted successfully!');
    } catch (error) {
      console.error('Error deleting article:', error);
      alert('Failed to delete article');
    }
  };

  const handlePhotoUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploading(true);
      try {
        const result = await uploadData({
          key: file.name,
          data: file
        });
        setPhotoName(result.key);
        alert('Photo uploaded successfully!');
      } catch (error) {
        console.error('Error uploading photo:', error);
        alert('Failed to upload photo');
      } finally {
        setUploading(false);
      }
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const modules = {
    toolbar: [
      [{ 'font': [] }, { 'size': ['small', 'normal', 'large', 'huge'] }],  // フォントオプションを追加
      [{ 'header': '1' }, { 'header': '2' }, { 'header': [3, 4, 5, 6] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'align': [] }],
      ['link', 'image'],
      ['clean']
    ]
  };

  const formats = [
    'header', 'font', 'size', 'list', 'bullet', 'bold', 'italic', 'underline',
    'strike', 'blockquote', 'color', 'background', 'align', 'link', 'image'
  ];

  return (
    <Box w="100%" h="100vh" display="flex" justifyContent="center" alignItems="center">
      <Box w="30%" p={4}>
        <VStack spacing={4} align="stretch">
          <Input placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
          <DatePicker
            selected={date}
            onChange={(date) => setDate(date)}
            dateFormat="yyyy/MM/dd"
            customInput={<Input placeholder="Date" />}
          />
          <Button onClick={handleClick} backgroundColor='#E6FFFA' color="black">画像を選択</Button>
          <Input 
            type="file" 
            ref={fileInputRef} 
            onChange={handlePhotoUpload} 
            style={{ display: 'none' }} 
          />
          {photoName && <Text>Photo Name: {photoName}</Text>}
          <Button onClick={onOpen}>Edit Article</Button>
          <Input placeholder="Slide ID" value={slideId} readOnly />
          <VStack>
            <Button width="100px" backgroundColor='#E6FFFA' onClick={handleUpload} isLoading={uploading}>作成</Button>
            <Button width="100px" backgroundColor='#E6FFFA' onClick={handleUpdate} isLoading={uploading}>修正</Button>
            <Button width="100px" backgroundColor='#E6FFFA' onClick={handleDelete} isLoading={uploading}>削除</Button>
          </VStack>
        </VStack>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent width="80%">
          <ModalHeader>Edit Article</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ReactQuill 
              value={article} 
              onChange={setArticle} 
              theme="snow" 
              style={{ height: '400px' }} 
              modules={modules}
              formats={formats}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ArticleUpload;










