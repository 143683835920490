import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToAnchor() {
  const location = useLocation();

  useEffect(() => {
    const scrollToElement = () => {
      // ハッシュがあるか、特定のパスにいるかを確認
      const hash = window.location.hash;
      const path = window.location.pathname;
      const yOffset = -80; // オフセットピクセル値
      let y = 0;

      if (hash) {
        // ハッシュがある場合、その要素にスクロール
        const element = document.querySelector(hash);
        if (element) {
          y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        }
      } else if (path === '/company' || path === '/contact-form') {
        // 特定のパスの場合、ページの先頭から85px下へスクロール
        y = window.pageYOffset + yOffset;
      }

      if (y !== 0) {
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    };

    scrollToElement();
  }, [location]);

  return null;
}

export default ScrollToAnchor;
