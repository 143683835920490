import React,{useEffect} from 'react';
import { Box, Text, VStack, Center,useBreakpointValue } from '@chakra-ui/react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useLanguage } from './LanguageContext';
  
function Mission() {
  const aosOffset = useBreakpointValue({ base: 250, md: 400 });

  useEffect(() => {
    AOS.init(
      {
        offset: aosOffset,  // アニメーションが発火するスクロール位置を上から200px前に設定
        duration: 1000,
      }
    ); // AOSを初期化
  }, []);

  const { language } = useLanguage();

  return (
    <Box>
      <VStack 
        marginTop={["0px","80px"]} 
        marginBottom={["0px","80px"]} 
        mx={["20px","60px","60px","0px"]} 
        spacing={4}
      >
        <Center my={{ base: "30px", md: "80px" }}>
          <Text
            fontSize={["20px","25px","30px","40px"]}
            fontFamily="'Helvetica'"
            position="relative"
            _after={{
              content: "''",
              position: 'absolute',
              display: 'block',
              height: '4px',
              width: '100%',
              bottom: '0',
              marginBottom: '-4px',
              background: 'linear-gradient(to right, #FFC857, #FFC857, #DCA7A7)',
            }}
          >    
            Our Mission
          </Text>
        </Center>
        {language === 'JP' ? (
          <Box textAlign="left" marginX={["15px","0 auto"]} marginTop={["0px","0px"]}>
             <Text fontSize={["14px","17px","17px","20px"]} mb={["20px","40px"]} data-aos="fade-up" >わたしって、無力だな。</Text> 
             
             <Text fontSize={["14px","17px","17px","20px"]} mb={["20px","20px"]} data-aos="fade-up" >子どもが生まれて、それまで夫と同じ仕事をしていたけれど、わたしだけが辞めた。</Text> 
             <Text fontSize={["14px","17px","17px","20px"]} mb={["20px","20px"]} data-aos="fade-up" >突如不登校になった子どもを抱え、正社員を辞めた。</Text> 
             <Text fontSize={["14px","17px","17px","20px"]} mb={["20px","20px"]} data-aos="fade-up" >やりたい仕事を諦めた。</Text> 
             <Text fontSize={["14px","17px","17px","20px"]} mb={["20px","20px"]} data-aos="fade-up" >一番大切な、子どもを守るために。</Text> 
             
             <Text fontSize={["14px","17px","17px","20px"]} mt={["20px","60px"]} mb={["10px","20px"]} data-aos="fade-up" >子どもを見守りながらできる仕事を必死で探した。</Text> 
             <Text fontSize={["14px","17px","17px","20px"]} mb={["20px","20px"]} data-aos="fade-up" >でも、どれだけ良いアイデアを出しても、評価やボーナスに反映されることはなくなった。</Text> 
             
             <Text fontSize={["14px","17px","17px","20px"]} mt={["20px","60px"]} mb={["10px","20px"]} data-aos="fade-up" >一方で、子どもが大きくなった未来を考えれば考えるほど、</Text> 
             <Text fontSize={["14px","17px","17px","20px"]} mb={["20px","20px"]} data-aos="fade-up" > 「この社会に子どもを送り出していいのかな」と思えてくる。</Text> 
             <Text fontSize={["14px","17px","17px","20px"]} mb={["20px","20px"]} data-aos="fade-up" > でも、子どもがいるから。わたしは母親だから。</Text> 
             <Text fontSize={["14px","17px","17px","20px"]} mb={["20px","20px"]} data-aos="fade-up" > そんな、未来を創るような仕事なんて無理だと諦めていた。</Text> 
            
             <Text fontSize={["14px","17px","17px","20px"]} mt={["20px","60px"]} mb={["10px","20px"]} data-aos="fade-up" >そんなわたしが、Web3に出会った。</Text> 
             <Text fontSize={["14px","17px","17px","20px"]} mb={["20px","20px"]} data-aos="fade-up" > DAO、トレーサビリティ、トークンエコノミー…そんな未来を予感させる言葉たちにわくわくした。</Text> 
             <Text fontSize={["14px","17px","17px","20px"]} mb={["20px","20px"]} data-aos="fade-up" > Web3なら、今の子どもを育てながら、未来の子どもが輝く社会を創れるかもしれない。</Text> 
             <Text fontSize={["14px","17px","17px","20px"]} mb={["20px","20px"]} data-aos="fade-up" > 子どもが大きくなった未来を誰よりも真剣に考えている母親だから、</Text> 
             <Text fontSize={["14px","17px","17px","20px"]} mb={["20px","20px"]} data-aos="fade-up" >  誰よりも、未来を創造していく力がある。</Text> 
            
             <Text fontSize={["14px","17px","17px","20px"]} mt={["20px","60px"]} mb={["10px","20px"]} data-aos="fade-up" >未来の子どもたちのために。</Text> 
             <Text fontSize={["14px","17px","17px","20px"]} mb={["20px","20px"]} data-aos="fade-up" >Web3で、新しい働き方のカタチを、つくっています。</Text> 
             
             <Text fontSize={["14px","17px","17px","20px"]} mt={["20px","60px"]} mb={["40px","60px"]} data-aos="fade-up" > Saki Honda 共同創業者&Chief Visionary</Text> 
          </Box>
        ):(
          <Box textAlign="left" marginX={["15px","0 auto"]} marginTop={["0px","0px"]}>
             <Text fontSize={["14px","20px"]} mb={["20px","40px"]} data-aos="fade-up" >I feel powerless.</Text> 
             
             <Text fontSize={["14px","20px"]} mb={["20px","20px"]} data-aos="fade-up" >After having a child, I was working the same job as my husband, but I was the only one who quit.</Text> 
             <Text fontSize={["14px","20px"]} mb={["20px","20px"]} data-aos="fade-up" >When my child suddenly stopped going to school, I had to quit my full-time job.</Text> 
             <Text fontSize={["14px","20px"]} mb={["20px","20px"]} data-aos="fade-up" > I gave up the job I wanted to do.</Text> 
             <Text fontSize={["14px","20px"]} mb={["20px","20px"]} data-aos="fade-up" >All to protect my most precious child.</Text> 
             
             <Text fontSize={["14px","20px"]} mt={["20px","60px"]} mb={["10px","20px"]} data-aos="fade-up" > I desperately searched for a job that I could do while keeping an eye on my child.</Text> 
             <Text fontSize={["14px","20px"]} mb={["20px","20px"]} data-aos="fade-up" >But no matter how good my ideas were, they no longer reflected in my evaluations or bonuses.</Text> 
             
             <Text fontSize={["14px","20px"]} mt={["20px","60px"]} mb={["10px","20px"]} data-aos="fade-up" >The more I think about the future when my child grows up,</Text> 
             <Text fontSize={["14px","20px"]} mb={["20px","20px"]} data-aos="fade-up" >  the more I wonder if it's okay to send them out into this society.</Text> 
             <Text fontSize={["14px","20px"]} mb={["20px","20px"]} data-aos="fade-up" > But I have children. I am a mother. </Text> 
             <Text fontSize={["14px","20px"]} mb={["20px","20px"]} data-aos="fade-up" > I had given up on the idea of having a job that shapes the future. </Text> 
            
             <Text fontSize={["14px","20px"]} mt={["20px","60px"]} mb={["10px","20px"]} data-aos="fade-up" >That's when I encountered Web3.</Text> 
             <Text fontSize={["14px","20px"]} mb={["20px","20px"]} data-aos="fade-up" > DAO, traceability, token economy... words that hint at a future excited me.</Text> 
             <Text fontSize={["14px","20px"]} mb={["20px","20px"]} data-aos="fade-up" > With Web3, while raising my child, I might be able to create a society where the future children can shine.</Text> 
             <Text fontSize={["14px","20px"]} mb={["20px","20px"]} data-aos="fade-up" > Because I am a mother who thinks more seriously about the future when my children grow up, </Text> 
             <Text fontSize={["14px","20px"]} mb={["20px","20px"]} data-aos="fade-up" >  I have more power to create the future than anyone else.</Text> 
            
             <Text fontSize={["14px","20px"]} mt={["20px","60px"]} mb={["10px","20px"]} data-aos="fade-up" >For the sake of the future children.</Text> 
             <Text fontSize={["14px","20px"]} mb={["20px","20px"]} data-aos="fade-up" > I am creating a new way of working with Web3.</Text> 
             
             <Text fontSize={["14px","20px"]} fontWeight="bold" mt={["20px","60px"]} mb={["40px","40px"]} data-aos="fade-up" > Saki Honda, Co-Founder, Chief Visionary</Text> 
          </Box>
        )}
      </VStack>
    </Box>
  );
}

export default Mission;
