import React, { useState, useEffect, useRef } from 'react';
import { Box, Flex, IconButton, Image, Text, VStack } from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { articlesData } from './ArticlesData';
import { useLanguage } from '../LanguageContext';

const Article = () => {
  const [articlesToShow, setArticlesToShow] = useState([]);
  const [transition, setTransition] = useState(true);
  const [index, setIndex] = useState(0);
  const intervalRef = useRef(null);
  const navigate = useNavigate();
  const { language } = useLanguage();

  useEffect(() => {
    const fetchArticles = async () => {
      try {
       
        // 画像URLをローカルのpublicディレクトリから取得
        const fetchedArticles = articlesData.map(article => ({
          id: article.id,
          title: article.title,
          date: article.date,
          imageUrl: `${process.env.PUBLIC_URL}/${article.PhotoName}`, // ローカルのpublicディレクトリのパスを使用
          link: article.link
        }));
        // 100枚くらいの記事を繰り返し使う
        const repeatedArticles = [];
        for (let i = 0; i < 100; i++) {
          repeatedArticles.push(...fetchedArticles);
        }
  
        setArticlesToShow([...repeatedArticles, ...repeatedArticles.slice(0, 3)]);
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };
  
    fetchArticles();
  }, []);
  
  const handleNext = () => {
    setIndex((prevIndex) => prevIndex + 1);
    if (index >= articlesToShow.length - 3) {
      setTimeout(() => {
        setTransition(false);
        setIndex(0);

        setArticlesToShow((prevArticles) => [...prevArticles.slice(3), ...prevArticles.slice(0, 3)]);
      }, 500); // 同じdurationを設定
    } else {
      setTransition(true);
    }
  };

  useEffect(() => {
    if (articlesToShow.length > 3) {
      intervalRef.current = setInterval(() => {
        handleNext();
      }, 2500);
      return () => clearInterval(intervalRef.current);
    }
  }, [articlesToShow, index]);

  return (
    <Box w="100%" mt="40px" overflow="hidden" position="relative">
      {/* <IconButton
        icon={<ArrowLeftIcon />}
        position="absolute"
        right="10rem"
        top="0.5rem"
        zIndex="1"
        onClick={handleNext}
        aria-label="Previous"
        borderRadius="full"
      />
      <IconButton
        icon={<ArrowRightIcon />}
        position="absolute"
        right="1rem"
        top="0.5rem"
        zIndex="1"
        onClick={handleNext}
        aria-label="Next"
        borderRadius="full"
        mr="100px"
      /> */}
      <Flex
        marginTop="60px"
        marginLeft={["30px","0px"]}
        transition={transition ? "transform 0.5s ease-in-out" : "none"}
        transform={`translateX(-${index * 350}px)`} // 300px (250px Box + 50px margin)
        width={`${articlesToShow.length * 350}px`}
      >
        {articlesToShow.map((article, idx) => (
          <Box
            key={idx}
            w="300px"
            h="320px"
            p={2}
            mx="50px"
            onClick={() => navigate(`/article-page/${article.id}`)}
            cursor="pointer"
            bg="#f5f5f5"
            boxShadow="lg"
            borderRadius="20px"
          >
            <VStack spacing={2} align="start"　p="5px">
              <Box borderRadius="20px"　overflow="hidden">
                 <Image src={article.imageUrl} alt={article.title[language]} w="100%" />
              </Box>
              <Text width="95%" mt={["20px","20px"]} fontWeight="bold">{article.title[language]}</Text>
              <Text textAlign="center" fontSize="sm">{article.date}</Text>
            </VStack>
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

export default Article;

