import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Image, Text } from '@chakra-ui/react';
import { articlesData } from './ArticlesData';
import { useLanguage } from '../LanguageContext';
import AOS from 'aos';
import 'aos/dist/aos.css';

const ArticlePage = () => {
  const { id } = useParams();
  const { language } = useLanguage();
  const article = articlesData.find(article => article.id === parseInt(id));

  useEffect(() => {
    window.scrollTo(0, 0); // ページの一番上にスクロールする
    AOS.init(); // AOSを初期化
  }, [id]);

  if (!article) {
    return <Text>Article not found</Text>;
  }

  return (
    <Box mt={["90px","80px"]} mb={["80px","120px"]} display="flex" flexDirection="column" alignItems="center" p={4}>
      <Image 
        src={`${process.env.PUBLIC_URL}/${article.PhotoName}`} 
        alt={article.title[language]} 
        w="80%" 
        mb={["30px","50px"]} 
        borderRadius="lg"
        boxShadow="2xl"
      />
      <Text 
        fontSize={["20px","24px" ]}
        w="80%" 
        mb={["30px","50px"]} 
        fontWeight="bold" 
      >
        {article.title[language]}
      </Text>
      <Box 
        w="80%" 
        fontSize={["14px","20px" ]}
        dangerouslySetInnerHTML={{ __html: article.article[language] }}
      />
    </Box>
  );
};

export default ArticlePage;
