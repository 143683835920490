// import { Box, Text, Button, ChakraProvider } from '@chakra-ui/react';
// import React,{useEffect} from 'react';
// import { useNavigate } from 'react-router-dom';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
// import YouTubeVideo from './YoutubeVideo';
  
// function Platform() {
//   useEffect(() => {
//     AOS.init(); // AOSを初期化
//   }, []);

//   const navigate = useNavigate(); // ナビゲート用のフック

//   const goToIdeaFlowBankHome = () => {
//     navigate('/ideaflowbank-home'); // パスを指定
//   };

//   return (
//     <ChakraProvider>
//       <Box
//         width={{ base: "100%", sm: "80%", md: "80%" }}
//         minH={{ base: "100%", sm: "100%", md: "80%" }} 
//         marginX="auto"
//         bg="#5ABCB9"
//         position="relative"
//         overflow="hidden" 
//       >
//           {/* <Box
//             position="relative"
//             width="full"
//             height={{ base: "auto", md: "auto" }}
//             pt={{ base: "20px", md: "0" }} 
//           > */}
//             <Box
//               position="relative"
//               display="flex"
//               flexDirection="column"
//               justifyContent="center"
//               textAlign="center"
//               alignItems="center"
//               px={{ base: "4", md: "8" }} 
//             >
//             <Box>
//             <Box
//                 display="inline-block"
//                 position="relative"
//                 textAlign="center"
//                 marginTop="30px"
//                 width="auto"
//               >
//               <Text
//                 display="inline-block"
//                 fontSize={{ base: "20px", sm:"25px",md: "30px", lg: "40px" }} 
//                 fontFamily="Helvetica"
//                 color="white"
//                 mt={["10px","30px","50px","80px"]}
//                 textAlign="center"
//                 _after={{
//                   content: "''",
//                   // position: 'relative',
//                   position:"absolute",
//                   display: 'block',
//                   height: '4px',
//                   width: '100%',
//                   bottom: '0',
//                   marginTop: '0px',
//                   background: 'linear-gradient(to right, #FFC857, #FFC857, #DCA7A7)',
//                 }}
//               >
//                 Web3 DAO Platform
//               </Text>
//               </Box>

//               <Text
//                 fontSize={{ base: "36px", md: "60px", lg: "96px" }}
//                 data-aos="fade-up"
//                 fontFamily="Montserrat"
//                 fontStyle="italic"
//                 fontWeight="900"
//                 letterSpacing={{ base: "-1.0px", md: "-1.5px" }} 
//                 color="white"
//                 mt={{ base: "20px", md: "30px" }} 
//               >
//                 Idea flow Bank
//               </Text>
//               <Text
//                 fontSize={{ base: "36px", md: "60px", lg: "96px" }}
//                 fontFamily="Montserrat"
//                 fontStyle="italic"
//                 fontWeight="900"
//                 letterSpacing={{ base: "-1.0px", md: "-1.5px" }}
//                 color="transparent"
//                 sx={{
//                   WebkitTextStroke: "1px white",
//                   position: "relative", 
//                   mt: { base: "-130px", md: "-130px", lg: "-130px" },
//                   opacity: 0.5, 
//                   transform: {
//                     base: "translateX(-2px) translateY(80px)", // ベースサイズでの調整
//                     md: "translateX(-4px) translateY(42px)", // 中サイズでの調整
//                     lg: "translateX(-8px) translateY(-8px)" // 大サイズでの調整
//                   } 
//                 }}
//                   >
//                     Idea flow Bank
//                   </Text>
//             </Box>

//             <Box 
//               position="relative" 
//               width={["100%","100%"]} 
//               height={["100%","100%"]} 
//               marginTop={["100px","120px","100px","30px"]}
//               marginBottom={["0px","0px","0px","30px"]}
//               display="flex" // 追加
//               justifyContent="center" // 追加
//             >
//                 <YouTubeVideo videoId="2PEwK8rwL2w" />
//             </Box>

//               <Button
//                 onClick={goToIdeaFlowBankHome} 
//                 fontSize={{ base: "16px", md: "20px", lg: "24px" }} // ボタンのフォントサイズをレスポンシブに
//                 fontFamily="Helvetica"
//                 data-aos="flip-left"
//                 color="white"
//                 mt={["60px","40px"]}
//                 mb={["0px","0px"]}
//                 bg="#FFC857"
//                 _hover={{ bg: "#e6b347" }}
//                 w={{ base: "200px", sm: "200px", md: "280px" }} // ボタンの幅をレスポンシブに
//                 h={{ base: "32px", md: "48px" }} 
//                 borderRadius="20px"
//                 border="2px solid white"
//                 marginBottom={["20px","40px","20px","20px"]}
//               >
//                 More Info
//               </Button>
//             </Box>
//           {/* </Box> */}
//       </Box>
//     </ChakraProvider>
//   );
// }

// export default Platform;


import { Box, Text, Button, ChakraProvider } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import YouTubeVideo from './YoutubeVideo';

function Platform() {
  useEffect(() => {
    AOS.init(); // AOSを初期化
  }, []);

  const navigate = useNavigate(); // ナビゲート用のフック

  const goToIdeaFlowBankHome = () => {
    navigate('/ideaflowbank-home'); // パスを指定
  };

  return (
    <ChakraProvider>
      <Box
        width={{ base: "100%", sm: "80%", md: "80%" }}
        minH={{ base: "100%", sm: "100%", md: "80%" }}
        marginX="auto"
        bg="#5ABCB9"
        position="relative"
        overflow="hidden"
      >
        <Box
          position="relative"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          textAlign="center"
          alignItems="center"
          px={{ base: "4", md: "8" }}
        >
          <Box>
            <Box
              display="inline-block"
              position="relative"
              textAlign="center"
              marginTop="30px"
              width="auto"
            >
              <Text
                display="inline-block"
                fontSize={{ base: "20px", sm: "25px", md: "30px", lg: "40px" }}
                fontFamily="Helvetica"
                color="white"
                mt={["10px", "30px", "50px", "80px"]}
                textAlign="center"
                _after={{
                  content: "''",
                  position: "absolute",
                  display: "block",
                  height: "4px",
                  width: "100%",
                  bottom: "0",
                  marginTop: "0px",
                  background: "linear-gradient(to right, #FFC857, #FFC857, #DCA7A7)",
                }}
              >
                Web3 DAO Platform
              </Text>
            </Box>

            <Text
              fontSize={{ base: "36px", md: "60px", lg: "96px" }}
              data-aos="fade-up"
              fontFamily="Montserrat"
              fontStyle="italic"
              fontWeight="900"
              letterSpacing={{ base: "-1.0px", md: "-1.5px" }}
              color="white"
              mt={{ base: "20px", md: "30px" }}
            >
              Idea flow Bank
            </Text>
            <Text
              fontSize={{ base: "36px", md: "60px", lg: "96px" }}
              fontFamily="Montserrat"
              fontStyle="italic"
              fontWeight="900"
              letterSpacing={{ base: "-1.0px", md: "-1.5px" }}
              color="transparent"
              sx={{
                WebkitTextStroke: "1px white",
                position: "relative",
                mt: { base: "-130px", md: "-130px", lg: "-130px" },
                opacity: 0.5,
                transform: {
                  base: "translateX(-2px) translateY(80px)",
                  md: "translateX(-4px) translateY(42px)",
                  lg: "translateX(-8px) translateY(-8px)"
                }
              }}
            >
              Idea flow Bank
            </Text>
          </Box>

          <Box
            position="relative"
            width={["100%", "100%"]}
            height={["100%", "100%"]}
            marginTop={["100px", "120px", "100px", "30px"]}
            marginBottom={["0px", "0px", "0px", "30px"]}
            display="flex"
            justifyContent="center"
          >
            <YouTubeVideo videoId="2PEwK8rwL2w" />
          </Box>

          <Button
            onClick={goToIdeaFlowBankHome}
            fontSize={{ base: "16px", md: "20px", lg: "24px" }}
            fontFamily="Helvetica"
            data-aos="flip-left"
            color="white"
            mt={["60px", "40px"]}
            mb={["0px", "0px"]}
            bg="#FFC857"
            _hover={{ bg: "#e6b347" }}
            w={{ base: "200px", sm: "200px", md: "280px" }}
            h={{ base: "32px", md: "48px" }}
            borderRadius="20px"
            border="2px solid white"
            marginBottom={["20px", "40px", "20px", "20px"]}
          >
            More Info
          </Button>
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default Platform;

